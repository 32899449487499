<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data">
  <v-container
    id="TIPOLOGICHE"
    fluid
    tag="section"
  >
    <v-row>
        <v-col :cols="isEditTipologiche ? '4' : '12'">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-earth-arrow-right"
            :title="$t('tipologiche.gestione')"
            class="px-5 py-3"
          >
            <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
              <v-row>
                <v-col>
                  <v-card-title>
                    <v-select
                      :items="eTipologiche"
                      return-object
                      item-text="cDescrizione"
                      item-value="id"
                      :label="$t('tipologiche.selezione')"
                      v-model="elTipologicaSel"
                      @change="onElencoTipologicaChanged($event)"
                    >
                    </v-select>
                  </v-card-title>
                </v-col>
                <v-col align="end">
                  <v-btn color="darkred" width="150px" @click="onNuovaTipologica">
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    {{ $t("tipologiche.nuovaTipologica") }}
                  </v-btn>
                  <v-btn color="darkred" width="180px" @click="onExportGrid" v-show="false">
                    <v-icon left> mdi-export </v-icon>
                    {{ $t("esporta") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-data-table
              style="max-height:600px; overflow:auto"
              id="tblTipologiche"
              :items="tipologiche.source"
              :headers="headersColumns"
              :search="search"
              :value="[tipologiche.selected]"
              @click:row="onTableRowClick"
              single-select
              :items-per-page="-1"
              class="elevation-1"
              :loading="isloading_tipologica"
              loading-text="Caricamento dati in corso..."
            >
            </v-data-table>
          </base-material-card>
        </v-col>

        <v-col v-if="isEditTipologiche" cols="8">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-earth"
            :title="$t('tipologiche.nomeTipol') + ': ' + elTipologicaSel.cDescrizione"
            class="px-5 py-3"
          >
            <v-card flat>
              <v-row>
                <v-card-title
                  class="black--text"
                  style="margin-bottom: 20px; margin-top: 20px"
                  >{{ $t("anagrafica") }}
                </v-card-title>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="tipologiche.selected.cDescrizione"
                    :label="$t('tipologiche.descrizioneObbl')"
                    :rules="[rules.required]"
                    maxlength="50"
                    :disabled="shouldDisable"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="tipologiche.selected.flagVisibile"
                    :label="$t('tipologiche.attivo')"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row v-if="is_t_veicolo_carbur_visible">
                <v-col>
                  <v-text-field
                    v-model="tipologiche.selected.nCoefConv"
                    :label="$t('tipologiche.coefficienteConversione')"
                    type="number"
                    min="1"
                    max="999"
                    :rules="[rules.valueMin(tipologiche.selected.nCoefConv, 0), rules.valueMax(tipologiche.selected.nCoefConv, 999)]"
                    :disabled="shouldDisable"
                    @keydown="onKeyDown"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="tipologiche.selected.nPrezzoMedio"
                    :label="$t('tipologiche.prezzoMedio')"
                    type="number"
                    min="1"
                    max="999"
                    step="0.01"
                    :rules="[rules.valueMin(tipologiche.selected.nPrezzoMedio, 0), rules.valueMax(tipologiche.selected.nPrezzoMedio, 999)]"
                    :disabled="shouldDisable"
                    @keydown="onKeyDownOnlyNegative"
                  >
                  </v-text-field>                
                </v-col>
              </v-row>
              
              <v-row  v-if="is_t_scadenza_tipo_visible">
                <v-col>
                  <v-select
                      :items="t_scadenza_categoria"
                      return-object
                      item-text="cDescrizione"
                      item-value="id"
                      :label="$t('tipologiche.categoriaScad')"
                      v-model="tipologiche.selected.tScadenzaCategoria"
                      :disabled="shouldDisable"
                    >
                  </v-select>
                </v-col>
                <v-col>
                </v-col>
              </v-row>

              <v-row v-if="is_t_scadenza_categoria_visible">
                <v-col>
                  <v-text-field
                    v-model="tipologiche.selected.nGgPrecNotifica"
                    :label="$t('tipologiche.ggPrecNotifica')"
                    type="number"
                    min="1"
                    max="99999"
                    :rules="[rules.valueMin(tipologiche.selected.nGgPrecNotifica, 0), rules.valueMax(tipologiche.selected.nGgPrecNotifica, 99999)]"
                    :disabled="shouldDisable"
                    @keydown="onKeyDown"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="tipologiche.selected.nGgRipNotifica"
                    :label="$t('tipologiche.ggRipNotifica')"
                    type="number"
                    min="1"
                    max="99999"
                    :rules="[rules.valueMin(tipologiche.selected.nGgRipNotifica, 0), rules.valueMax(tipologiche.selected.nGgRipNotifica, 99999)]"
                    :disabled="shouldDisable"
                    @keydown="onKeyDown"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="is_t_intervento_tipo_visible"> 
                <v-col>
                  <v-checkbox
                    v-model="tipologiche.selected.flagFiscale"
                    :label="$t('tipologiche.flagFiscale')"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>
                <v-col>
                </v-col>
              </v-row>

              <v-row v-if="is_t_documento_visible">
                <v-col>
                  <v-text-field
                    v-model="tipologiche.selected.cTipoOggetto"
                    :label="$t('tipologiche.docTipoOggetto')"
                    max="1"
                    :rules="[rules.required]"
                    :disabled="shouldDisable"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                </v-col>
              </v-row>

              <v-divider class="mx-4"></v-divider>
              <v-row
                style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
              >
                <v-alert
                  id="alertDettaglio"
                  shaped
                  :value="isAlertDetailVisible"
                  :type="alertType"
                  dismissible
                >
                  {{ messaggioAlert }}
                </v-alert>
              </v-row>

              <v-row
                style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
              >
                <v-btn
                  tile
                  color="darkred"
                  v-if="!isHidden"
                  v-on:click="isHidden = true"
                  @click="onEditClick"
                >
                  <v-icon left> mdi-pencil </v-icon>
                  {{ $t("modifica") }}
                </v-btn>
                <v-btn
                  tile
                  color="darkred"
                  v-if="isHidden"
                  @click="onSaveClick"
                >
                  <v-icon left> mdi-content-save </v-icon>
                  {{ $t("salva") }}
                </v-btn>
                <v-btn tile color="darkred" @click="onDisattivaClick">
                  <v-icon left> mdi-delete </v-icon>
                  {{ $t("cancella") }}
                </v-btn>
                <v-btn
                  id="btnChiudi"
                  tile
                  color="darkred"
                  @click="onCloseDetail"
                >
                  <v-icon left> mdi-door-open </v-icon>
                  {{ $t("chiudi") }}
                </v-btn>
                <v-dialog v-model="dialogDisattivaTipologica" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Sei sicuro di voler disattivare la tipologica selezionata?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onCloseDisattivaTipologica"
                        >Annulla</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onConfirmDisattivaTipologica"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-card>
          </base-material-card>
          
        </v-col>
      </v-row>

      <v-row>
        <v-alert
          id="alertGrid"
          shaped
          :value="isAlertGridVisible"
          :type="alertType"
          dismissible
        >
          {{ messaggioAlert }}
        </v-alert>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";
import Utils from "@/utils/Utils";

import TipologicheService from "@/service/TipologicheService";

@Component
export default class Tipologiche extends Vue {
  tipologiche = {
    selected: null,
    source: [],
  };

  headersColumns = [
    { text: "ID", value: "id", align: "start" },
    { text: "Descrizione", value: "cDescrizione" },
    { text: "Attivo", value: "flagVisibile" },
  ];


  eDocTipoOggetto = ["A", "V", "D", "B"];

  eTipologiche = [];

  t_scadenza_categoria = [];

  elTipologicaSel = null;

  isloading_tipologica = false;
  isEditTipologiche = false;
  isCreatedCorrectly= false;
  isAlertGridVisible = false;
  isAlertDetailVisible = false;
  shouldDisable = true;
  isHidden = false;
  isNewTipologica = false;
  saveClicked = false;
  dialogDisattivaTipologica = false;
  is_t_veicolo_carbur_visible = false;
  is_t_scadenza_tipo_visible = false;
  is_t_scadenza_categoria_visible = false;
  is_t_intervento_tipo_visible = false;
  is_t_documento_visible = false;

  dimension = 0;

  messaggioAlert = "";
  alertType = "error";
  search = "";

  tipologicaTemplate = null;

  rules = Utils.rules;


  created() {
    let verLoadingPage = Utils.verifyLoadingPage(
      this.$store,
      "W_PROFILAZIONEUTENTE"
    );
    switch (verLoadingPage) {
      case 0: // All OK
        this.isCreatedCorrectly = true;
        var enteId = this.$store.state.enteSel.id;

        this.loadElencoTipologiche(0);
        this.loadTemplateTipologiche();

        break;

      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }
  }

  onTableRowClick(tipolSel) {
    // Setto la riga selezionata
    this.tipologiche.selected = tipolSel;

    console.log("Tipologica selezionata: ");
    console.log(this.tipologiche.selected);

    //this.manageEntiVisibility(false);
    // TODO
    //this.onEnteChanged();

    this.isHidden = false;
    this.shouldDisable = true;
    this.isEditTipologiche = true;
  }

  onKeyDown(key) {
    if (key.key == "." || key.key == "," || key.key == "-")
    {
      key.preventDefault();
    }
    else
      return true;
  }

  onKeyDownOnlyNegative(key) {
    if (key.key == "-")
    {
      key.preventDefault();
    }
    else
      return true;
  }

  onElencoTipologicaChanged(tipol) {
    this.tipologiche.selected = null;
    this.isEditTipologiche = false;
    this.isAlertGridVisible = false;

    this.loadAllTipologica(tipol.id);

    // Azzero le variabili
    this.is_t_veicolo_carbur_visible = false;
    this.is_t_documento_visible = false;
    this.is_t_intervento_tipo_visible = false;
    this.is_t_scadenza_categoria_visible = false;
    this.is_t_scadenza_tipo_visible = false;
    switch (tipol.id) {
      case 1: // Tipo Carburante
        this.is_t_veicolo_carbur_visible = true;
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      case 5:
        break;
      case 6:
        break;
      case 7: // Scadenza Tipo
        this.is_t_scadenza_tipo_visible = true;
        this.loadTScadenzaCategoria();
        break;
      case 8: // Scadenza Categoria
        this.is_t_scadenza_categoria_visible = true;
        break;
      case 9:
        break;
      case 10:  // Tipo Intervento
        this.is_t_intervento_tipo_visible = true;
        break;
      case 11:
        break;
      case 12:  // Tipo Documento
        this.is_t_documento_visible = true;
        break;
    }
  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById("tblTipologiche").offsetWidth;
      return self.dimension;
    });
  }

  onNuovaTipologica() {
    if (this.elTipologicaSel == null)
    {
      this.alertType = "error";
      // In caso di errore del salvataggio continuare con l'editing
      this.messaggioAlert = i18n.t("tipologiche.erroreSelezione");
      this.showHide_alertGrid(8000);
      return;
    }

    this.saveClicked = false;
    this.isNewTipologica = true;
    this.isEditTipologiche = true;
    this.shouldDisable = false;
    this.isHidden = true;

    this.tipologiche.selected = Object.assign({}, this.tipologicaTemplate);

    this.onContainerChange();

    //this.manageEntiVisibility(true);

    //this.onEnteChanged();

    this.$refs.form.resetValidation();

  }

  onEditClick() {
    this.shouldDisable = false;
  }

  onSaveClick() {
    // Verifico se i campi della form sono tutti validi.
    // Per funzionare nel template ci deve essere un oggetto di tipo V-FORM
    this.saveClicked = true;
    var formIsValid = this.$refs.form.validate();
    if (!formIsValid) {
      this.alertType = "error";
      // In caso di errore del salvataggio continuare con l'editing
      this.messaggioAlert = i18n.t("tipologiche.erroreCampiObbligatori");
      this.showHide_alertDetail(8000);
      return;
    } else {
      this.tipologiche.selected.codTipologica = this.elTipologicaSel.id;
      if (this.elTipologicaSel.id == 12)
      {
        if (this.tipologiche.selected.cTipoOggetto != "A" &&
          this.tipologiche.selected.cTipoOggetto != "B" &&
          this.tipologiche.selected.cTipoOggetto != "D" &&
          this.tipologiche.selected.cTipoOggetto != "V")
          {
            this.alertType = "error";
            this.messaggioAlert = i18n.t("tipologiche.erroreValoriPossibiliTipoOggetto");
            this.showHide_alertDetail(8000);
            return;
          }
      }
      if (this.tipologiche.selected.id > 0) {
        // Salvo il dato sul DB
        this.saveTipologicaDto(this.tipologiche.selected.id);
      } else {
        // Creo il nuovo record
        this.createTipologicaDto();
      }
    }
  }

  onDisattivaClick() {
    this.dialogDisattivaTipologica = true;
  }

  onCloseDisattivaTipologica() {
    this.dialogDisattivaTipologica = false;
  }

  onConfirmDisattivaTipologica() {
    this.disattivaTipologicaDto(this.tipologiche.selected.id);
    this.dialogDisattivaTipologica = false;
  }

  onCloseDetail() {
    this.isEditTipologiche = false;
    this.onContainerChange();
    this.shouldDisable = true;
    this.isHidden = false;
  }

  onExportGrid() {
    // TODO
  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      console.log(
        "hide alert after " + millisecondInterval / 1000 + " seconds"
      );
    }, millisecondInterval);
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  // ************************
  // TIPOLOGICHE VEICOLI
  // ************************
  loadElencoTipologiche() {
    var self = this;

    TipologicheService.getElencoTipologiche(
      (resp) => {
        console.log("getElencoTipologiche");
        console.log(resp);

        self.eTipologiche = resp;
        self.elTipologicaSel = null;
      },
      (err) => {
        console.log("Errore getElencoTipologiche");
        console.log(err);
      }
    );
  }

  loadAllTipologica(codTip) {
    var self = this;
    self.isloading_tipologica = true;

    TipologicheService.getAllTipologica(
      codTip,
      (resp) => {
        console.log("Elenco Tipologiche con id " + codTip);
        console.log(resp);
        self.isloading_tipologica = false;
        if (Utils.isNullOrUndefinedOrEmpty(resp))
        {
          self.tipologiche.source = [];
          self.alertType = "error";
          self.messaggioAlert = i18n.t("tipologiche.erroreCaricamentoTipologica");
          self.showHide_alertGrid(8000);
        }
        else
        {
          self.tipologiche.source = resp;
        }
      },
      (err) => {
        console.log("Errore getAllTipologica");
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("tipologiche.erroreCaricamentoTipologica");
        self.showHide_alertGrid(8000);
        self.isloading_tipologica = false;
      }
    );
  }

  loadTemplateTipologiche() {
    var self = this;

    TipologicheService.getTemplateTipologica(
      (resp) => {
        console.log("GetTemplateTipologica:");
        console.log(resp);

        self.tipologicaTemplate = resp;
        self.tipologicaTemplate.cDescrizione = "";
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  loadTScadenzaCategoria() {
    var self = this;

    TipologicheService.getTScadenzaCategoria(
      (resp) => {
        console.log("GetTScadenzaCategoria");
        console.log(resp);

        self.t_scadenza_categoria = resp;
      },
      (err) => {
        console.log("Errore GetTScadenzaCategoria");
        console.log(err);
      }
    );
  }

createTipologicaDto() {
    var self = this;

    TipologicheService.createTipologica(
      self.tipologiche.selected,
      (resp) => {
        console.log("createTipologica");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Aggiorno la griglia principale
          self.updateGridTipologiche(resp.idNewObject);
          self.isNewTipologica = false;
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  saveTipologicaDto(id) {
    var self = this;

    TipologicheService.updateTipologica(
      self.tipologiche.selected,
      (resp) => {
        console.log("updateTipologica");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          // Aggiorno la griglia principale
          self.updateGridTipologiche(id);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  updateGridTipologiche(id, onSuccess) {
    var self = this;

    TipologicheService.getTipologica(
      self.elTipologicaSel.id,
      id,
      (resp) => {
        console.log("Tipologica:");
        console.log(resp);

        var objTmp = resp;

        if (typeof onSuccess === "function") onSuccess(resp);
        // Aggiorno la griglia
        var found = false;
        for (let i = 0; i < self.tipologiche.source.length; i++) {
          var item = self.tipologiche.source[i];
          if (item.id == id) {
            found = true;
            self.tipologiche.source.splice(i, 1);
            self.tipologiche.source.push(objTmp);
            self.tipologiche.selected = objTmp;
            break;
          }
        }
        if (!found) {
          // Caso di nuovo oggetto
          self.tipologiche.source.push(objTmp);
          self.tipologiche.selected = objTmp;
        }
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
      }
    );
  }

  disattivaTipologicaDto(id) {
    var self = this;
    TipologicheService.deleteTipologica(
      self.elTipologicaSel.id,
      self.tipologiche.selected.id,
      (resp) => {
        console.log("DeleteTipologica");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Azzero l'oggetto
          self.tipologiche.selected = null;
          // Ricarico la griglia
          self.loadAllTipologica(self.elTipologicaSel.id);
          self.isEditTipologiche = false;
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(5000);
      }
    );
  }

}
</script>